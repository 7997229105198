import React from "react"
import { Page, Seo } from "gatsby-theme-portfolio-minimal"
import { Section } from "gatsby-theme-portfolio-minimal/src/components/Section"

export default function TitleWebSolutionsPage() {
  return (
    <>
      <Seo title="Title Web Solutions" useTitleTemplate={true} noIndex={true} />
      <Page>
        <Section>
          <h1 id="top" style={{ textAlign: "center", marginBottom: "50px" }}>
            Title Web Solutions
          </h1>
          <p>
            I've been doing direct an contract work through my one-man-business
            for clients from all around the globe since 2005. <br />
            Currently I'm not very active as I'm having 2 kids and also a{" "}
            <a href="/work/#danskebank-2020">full time job</a>. <br />
            You can read more about some of the work I've done under the{" "}
            <a href="/work#graenseforeningen-2018">work</a> section.
          </p>
          <p>
            Even though I'm barely taking on any work these days, you're always
            welcome to get in touch.
          </p>

          <h2>Contact details</h2>
          <p>
            Title Web Solutions
            <br />
            <em>v. Anselm Christophersen</em>
            <br />
            Folkvarsvej 6, 4.tv <br />
            DK-2000 Frederiksberg <br />
            <br />
            <br />
            CVR/VAT No.: DK28426496 <br />
            Phone: +45 6015 1100 <br />
            Email: <a href="mailto:mail@title.dk">mail@title.dk</a> <br />
          </p>
        </Section>
      </Page>
    </>
  )
}
